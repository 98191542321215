import { Form  } from "semantic-ui-react";
import IInputWithUnitProps from "./IInputWithUnit";
import useInputValidation from "./useInputValidation";

const InputWithUnit:React.FC<IInputWithUnitProps> = (props: IInputWithUnitProps) => {

    const { validateInput } = useInputValidation();
    const { label, value, unit, validateFor, onChangeFunc, onChangeFuncKey, ...rest} = props;

    return (
        <div className={(props.pxwidth ? "w" + props.pxwidth : "w50") + (props.required ? " required field with-unit" : " field with-unit")} key={props.id}>
            {label && <label htmlFor={props.id}>{label}</label>}
            <Form.Input
                {...rest}
                onChange={(event) => {
                    onChangeFuncKey ?
                        (event.target.value==="" ? onChangeFunc(null,onChangeFuncKey) : onChangeFunc(parseFloat(event.target.value),onChangeFuncKey))
                    :
                        (event.target.value==="" ? onChangeFunc(null) : onChangeFunc(parseFloat(event.target.value)))
                }}
                value={value===null ? "" : props.value}
                className="field-container"
                error={validateInput(value,validateFor)}
            />
            <div className="unit-container">{unit}</div>
        </div>
    )

}

export { InputWithUnit };