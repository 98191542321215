import { useTranslation } from 'react-i18next';
import { Container, Form, Grid } from 'semantic-ui-react';
import { PageTitle } from 'Components/Titles';
import { Release } from 'Components/VersionHistory';

const VersionHistory = () => {

    const { t } = useTranslation();

    return (
        <Container className="page versions">
            <Form>

                <PageTitle title={t('pages.versionHistory')} />

                <Grid stackable>
                   
                    {/* < ADD NEW VERSION ALWAYS ON THE TOP BUT BELOW THIS LINE > */}

                    {/* REMEMBER TO UPDATE package.json VERSION NUMBER !!! */}

                    <Release version="1.0.1"
                        date="15.11.2024"
                        changes={[
                            "SharePoint: Lisätty linkki navigaatioon"
                        ]}
                        features={[]}
                        bugs={[]}
                    />

                    <Release version="1.0.0"
                        date="31.10.2024"
                        description='Ekopullo jäsenportaali julkaistu 31.10.2024.'
                        changes={[]}
                        features={[
                            "Profiili: Käyttäjän tiedot profiilin takaa",
                            "Lasinpalautuskyselyt: Kysely avautuu tammikuussa.",
                            "Siirtorahtikyselyt: Kysely avautuu marraskuussa",
                            "Budjettikyselyt: Kysely avautuu elokuussa",
                            "Jäsenvolyymikyselyt: Kysely avautuu tammikuussa",
                            "Inventaari-kyselyt: Kysely avautuu joka kuun lopussa",
                            "Kausivarastoinnin kustannuskyselyt: Kysely avautuu tammikuussa",
                            "Jakelurahtikyselyt: Kysely avautuu marraskuussa",
                            "Tasauskyselyt: Kysely avautuu marraskuussa",
                            "Tiedostot: Raportit ja tiedostot SharePointissa"
                        ]}
                        bugs={[]}
                    />

                    {/* < OLD VERSIONS BELOW THIS LINE > */}

                </Grid>
            </Form>
        </Container>
    );

}

export default VersionHistory;
